<div id="login-successful" class="no-hf office-page">
    <div class="container">
        <a class="home-anchor" href="/">
<!--            <img src="./assets/img/logo.svg">-->
            <img src="./assets/img/logo-800.jpg">
        </a>

        <h2 class="h-large">{{ 'login.successful.lbl.001' | translate }}</h2>

        <p class="description">
            <span>{{ member.username }}</span>
            {{ 'login.successful.lbl.002' | translate }}
            {{ 'login.successful.lbl.003' | translate }}
        </p>

        <div class="row btns">
            <a class="redirect-user-profile col-auto light-btn" href="/my-office/user-profile/change-resident-id">
                <span>{{ 'login.successful.lbl.004' | translate }}</span>

            </a>
            <a class="redirect-my-office col-auto primary-btn" href="/my-office">
                <span>{{ 'login.successful.lbl.005' | translate }}</span>

            </a>
        </div>

        <div class="additional-info">
        <p class="warning">
            {{ 'login.successful.lbl.006' | translate }}<br/>
            {{ 'login.successful.lbl.007' | translate }}
        </p>
        </div>
    </div>
</div>


import { Pipe, PipeTransform } from '@angular/core';
import { ConvertUtil } from '@app/common/util/convert.util';
import { environment } from '@enviroments/environment';
declare const addZeroInPrice: any;
@Pipe({ name: "setComma" })
export class SetCommaPipe implements PipeTransform {

  constructor() {}

  transform(value: String | Number | string | number | undefined, type : String | string | undefined, comId : String | string | undefined): String {
    let defaultLang = environment.default_lang;
    let lang = '';

    if(comId != null && comId != ''){
      lang = comId.slice(-2);
    }else{
      lang = environment.comId.substring(environment.comId.length-2);
    }
    if (!value) {
      return "0";
    }else{
      if(lang=='TH'){
        if(type =='price'){
          return ConvertUtil.setCommaAmt(value);
        }
        return ConvertUtil.setComma(value);
      }
     if(defaultLang != lang){
        if(defaultLang == 'US'){
          value = Math.round((+value / 1350) * 100)/100;
        }else if(defaultLang == 'KR'){
          value = Math.round((+value * 1350)*100)/100;
        }
      }
      return ConvertUtil.setComma(value);
    }
  }
}


import { Pipe, PipeTransform } from '@angular/core';
import { ValidationUtil } from '@app/common/util/validation.util';
import {TranslateService} from "@ngx-translate/core";

@Pipe({ name: "translateOptions" })
export class TranslatePipe implements PipeTransform {
  constructor(
    private _translate: TranslateService,
  ) {}

  transform(value: string | String, lang: string): string {
    if(ValidationUtil.isNullOrEmpty(value)){
      return "";
    }
    this._translate.use('messages.'+lang);
    return this._translate.instant(value+'');
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  urlBetaIzex : "https://apl.beta-izex.ru/api/",
  keyBetaIzex : "88956aba576362ca65e2d1fcd3e9295a",
 urlJavaScriptKBank : "https://kpaymentgateway.kasikornbank.com",
  production: false,
  default_lang: 'US',
  second_lang: 'kr',
  comId: 'MEDIUSTH',
  comCd: '04',
   urlWownet: 'https://v615.wownet.biz',
  apiUrl: 'https://v615.wownet.biz/api'
  //apiUrl : 'http://118.69.170.17:8100/api'
  // apiUrl: 'http://localhost:8100/api'
};



